import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import axios from '@axios'
import store from '@/store'
import router from '@/router'

export default function useProductList() {
  // Use toast
  const toast = useToast()
  const isBusy = ref(true)

  const refProductListTable = ref(null)
  const yearFilter = ref(moment().format('YYYY'))
  const monthFilter = ref(moment().format('MM'))

  const searchParams = ref({
    page: 1,
    size: 20,
    total: 0,
    state: [],
    sell_type: [],
    product_name: [],
    brand_name: [],
    vendor_idx: [],
    vendor_name: [],
    legacy_vendor_no: [],
    tagby_product_no: [],
    product_no: [],
    category: [],
    created_at: [],
    ...router.history.current.query,
  })
  const requestList = ref([])
  const waitingRequestCount = ref(0)

  const searchKeys = [
    'state', 'sell_type', 'product_name', 'brand_name', 'vendor_idx',
    'vendor_name', 'legacy_vendor_no', 'tagby_product_no', 'product_no', 'category',
    'created_at',
  ]
  searchKeys.forEach(key => {
    if (searchParams.value[key] == null || searchParams.value[key] === '') {
      searchParams.value[key] = []
    } else if (typeof searchParams.value[key] === 'string') {
      searchParams.value[key] = [searchParams.value[key]]
    }
  })

  // Table Handlers
  const tableColumns = [
    { key: 'idx', label: 'idx' },
    { key: 'thumbnail_img_url', label: '' },
    { key: 'vendor', label: '벤더명(벤더번호)' },
    { key: 'name', label: '상품명(브랜드명)' },
    { key: 'product_no', label: '벤더품번(태그바이품번)' },
    { key: 'category', label: '카테고리' },
    { key: 'price', label: '판매가(공급가)' },
    { key: 'sell_type', label: '판매타입' },
    { key: 'state', label: '상품상태' },
    { key: 'created_at', label: '등록날짜', formatter: val => `${moment(val).format('YYYY-MM-DD HH:mm:ss')}` },
  ]
  const tableColumnsForRequest = [
    { key: 'idx', label: 'idx' },
    { key: 'thumbnail_img_url', label: '' },
    { key: 'vendor', label: '벤더명' },
    { key: 'name', label: '상품명(브랜드명)' },
    { key: 'product_no', label: '벤더품번(태그바이품번)' },
    { key: 'restocked_at', label: '재입고 날짜', formatter: val => val ? `${moment(val).format('YYYY-MM-DD HH:mm:ss')}` : "" },
    { key: 'sell_type', label: '판매타입' },
    { key: 'reason', label: '요청타입' },
    { key: 'state', label: '상태' },
    { key: 'created_at', label: '요청날짜', formatter: val => `${moment(val).format('YYYY-MM-DD HH:mm:ss')}` },
  ]

  const resolveInvoiceStatusText = status => {
    if (status === 'INACTIVE') return '삭제'
    if (status === 'ACTIVE') return '판매중'
    if (status === 'SOLDOUT') return '매진'
    if (status === 'HIDDEN') return '숨김상태'
    if (status === 'WRITING') return '작성중'
    if (status === 'DELETING') return '삭제 요청중'
    if (status === 'CONFIRMING') return '승인 대기중'
    return ''
  }

  const resolveProductSellTypeText = status => {
    if (status === 'ALWAYS') return '상시형'
    if (status === 'RECRUITMENT') return '공모형'
    return ''
  }

  const translateReason = reason => {
    switch (reason) {
      case 'REGISTER':
        return '생성 요청중'
      case 'UPDATE':
        return '수정 요청중'
      case 'DELETE':
        return '삭제 요청중'
      default:
        return '알 수 없음'
    }
  }

  const translateRequestState = state => {
    switch (state) {
      case 'WAIT':
        return '대기중'
      case 'ACCEPT':
        return '승인완료'
      case 'REJECT':
        return '거부완료'
      case 'INACTIVE':
        return '삭제'
      default:
        return '알수없음'
    }
  }

  const resolveCategoryText = category => [category?.class_1, category?.class_2, category?.class_3].filter(e => e != null).join(',')

  const products = ref([])
  const totalCount = ref(searchParams.value.total)
  const perPage = ref(searchParams.value.size)
  const currentPage = ref(searchParams.value.page)
  const currentRequestPage = ref(1)
  const totalRequestCount = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refProductListTable.value ? refProductListTable.value.localItems.length : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCount.value,
    }
  })

  const datetimePreprocessing = datetime => datetime
    .map(o => o
      .split(' to ')
      .map(date => moment(new Date(date).setHours(0))
        .utc()
        .format('YYYY-MM-DD HH'))
      .join(' to '))

  const fetchProductList = () => {
    isBusy.value = true
    store
      .dispatch('app-invoice/fetchProductList', {
        ...searchParams.value,
        created_at: datetimePreprocessing(searchParams.value.created_at),
      })
      .then(response => {
        const { page_info, data } = response.data
        products.value = data
        totalCount.value = page_info.total_count
        searchParams.value.total = page_info.total_count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        isBusy.value = false
        router.replace({
          query: searchParams.value,
        }).catch(() => {})
      })
  }

  const fetchRequestList = () => {
    const queryParams = {
      page: currentRequestPage.value,
      size: perPage.value,
    }
    axios
      .get(
        `${process.env.VUE_APP_BACKEND_SERVER}/manage/product/request/`,
        { params: queryParams },
      )
      .then(res => {
        requestList.value = res.data.data.request_list
        waitingRequestCount.value = res.data.data.waiting_request_count
        totalRequestCount.value = res.data.data.total_count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching request list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const updateProductState = (idx, state) => {
    store
      .dispatch('app-invoice/updateProductState', {
        product_idx: idx,
        update_state: state,
      })
      .then(() => {
        currentPage.value = 1
        fetchProductList()
        toast({
          component: ToastificationContent,
          props: {
            title: '상태변경에 성공하였습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '상태변경에 실패하였습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  watch([currentPage, perPage], () => {
    searchParams.value.page = currentPage.value
    searchParams.value.size = perPage.value
    fetchProductList()
  })

  watch([currentRequestPage, perPage], () => {
    fetchRequestList()
  })

  return {
    fetchProductList,
    fetchRequestList,
    requestList,
    waitingRequestCount,
    tableColumns,
    tableColumnsForRequest,
    perPage,
    currentPage,
    currentRequestPage,
    totalCount,
    totalRequestCount,
    products,
    dataMeta,
    refProductListTable,

    yearFilter,
    monthFilter,
    searchParams,

    updateProductState,
    resolveInvoiceStatusText,
    resolveProductSellTypeText,
    translateReason,
    translateRequestState,
    resolveCategoryText,
  }
}
