<template>
  <div>
    <b-card class="p-100">
      <b-card-header>
        <h2 class="pb-50">
          검색 필터
        </h2>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="카테고리별 검색"
            >
              <b-row>
                <b-col
                  cols="12"
                  xl="2"
                >
                  <v-select
                    v-model="textCategorySelect"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="textCategoryOptions"
                    class="invoice-filter-select"
                    placeholder="검색 항목 선택"
                  >
                    <template #selected-option="{ label }">
                      <span class="text-truncate overflow-hidden">
                        {{ label }}
                      </span>
                    </template>
                  </v-select>
                </b-col>
                <b-col
                  v-if="isNotCheckbox"
                  cols="12"
                  xl="4"
                >
                  <b-input-group>
                    <flat-pickr
                      v-if="isDateInput"
                      v-model="textInput"
                      class="form-control"
                      :config="{ mode: 'range'}"
                    />

                    <b-form-input
                      v-else
                      v-model="textInput"
                      class="d-inline-block"
                      placeholder="검색 텍스트 입력"
                      @keyup.enter="addTag"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="addTag"
                      >
                        Add
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col
                  v-else
                  cols="12"
                  xl="10"
                  class="d-flex align-items-center"
                >
                  <b-form-checkbox-group
                    v-if="isStateInput"
                    v-model="searchParams.state"
                    :options="stateOptions"
                    name="buttons-1"
                  />
                  <b-form-checkbox-group
                    v-else-if="isSellTypeInput"
                    v-model="searchParams.sell_type"
                    :options="sellTypeOptions"
                    name="buttons-1"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-tags
              v-model="tagParams"
              input-id="tags-basic"
              class="mb-2"
              :tag-validator="() => false"
              invalid-tag-text="이곳에 직접 입력할 수 없습니다"
              placeholder="위에서 입력한 값을 보여줍니다"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="d-flex btn-box"
            style="justify-content: space-between;"
          >
            <b-button
              variant="gradient-primary"
              class="mr-1"
              @click="search"
            >
              검색
            </b-button>
            <b-button
              variant="gradient-primary"
              @click="createProduct"
            >
              상품 등록
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTags,
  BInputGroup,
  BInputGroupAppend,
  VBToggle,
  BSidebar,
  BFormInvalidFeedback,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
    flatPickr,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    searchParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      stateOptions: [
        { text: '판매중', value: 'ACTIVE' },
        { text: '매진', value: 'SOLDOUT' },
        { text: '승인대기중', value: 'CONFIRMING' },
        { text: '숨김상태', value: 'HIDDEN' },
        { text: '삭제', value: 'INACTIVE' },
      ],
      sellTypeOptions: [
        { text: '상시형', value: 'ALWAYS' },
        { text: '공모형', value: 'RECRUITMENT' },
      ],
      textCategoryOptions: [
        { label: '상품명', value: 'PRODUCT_NAME' },
        { label: '태그바이품번', value: 'TAGBY_PRODUCT_NO' },
        { label: '벤더품번', value: 'PRODUCT_NO' },
        { label: '벤더번호', value: 'VENDOR_IDX' },
        { label: '벤더명', value: 'VENDOR_NAME' },
        { label: '벤더번호(1.0)', value: 'LEGACY_VENDOR_NO' },
        { label: '상품상태', value: 'STATE' },
        { label: '판매타입', value: 'SELL_TYPE' },
        { label: '브랜드명', value: 'BRAND_NAME' },
        { label: '카테고리', value: 'CATEGORY' },
        { label: '등록날짜', value: 'CREATED_AT' },
      ],
      textCategorySelect: { label: '상품명', value: 'NAME' },
      textInput: '',
    }
  },
  computed: {
    isDateInput: {
      get() {
        return this.textCategorySelect?.value === 'CREATED_AT'
      },
    },
    isStateInput: {
      get() {
        return this.textCategorySelect?.value === 'STATE'
      },
    },
    isSellTypeInput: {
      get() {
        return this.textCategorySelect?.value === 'SELL_TYPE'
      },
    },
    isNotCheckbox: {
      get() {
        return !this.isStateInput && !this.isSellTypeInput
      },
    },
    tagParams: {
      get() {
        const results = []
        const state = this.stateOptions.filter(o => this.searchParams.state.includes(o.value)).map(o => `상품상태:${o.text}`)
        const sellType = this.sellTypeOptions.filter(o => this.searchParams.sell_type.includes(o.value)).map(o => `판매타입:${o.text}`)
        const productName = this.searchParams.product_name.map(o => `상품명:${o}`)
        const tagbyProductNo = this.searchParams.tagby_product_no.map(o => `태그바이품번:${o}`)
        const productNo = this.searchParams.product_no.map(o => `벤더품번:${o}`)
        const vendorIdx = this.searchParams.vendor_idx.map(o => `벤더번호:${o}`)
        const vendorName = this.searchParams.vendor_name.map(o => `벤더명:${o}`)
        const legacyVendorNo = this.searchParams.legacy_vendor_no.map(o => `벤더번호(1.0):${o}`)
        const brandName = this.searchParams.brand_name.map(o => `브랜드명:${o}`)
        const category = this.searchParams.category.map(o => `카테고리:${o}`)
        const createdAt = this.searchParams.created_at.map(o => `등록날짜:${o}`)
        return results
          .concat(state)
          .concat(sellType)
          .concat(productName)
          .concat(tagbyProductNo)
          .concat(productNo)
          .concat(vendorIdx)
          .concat(vendorName)
          .concat(legacyVendorNo)
          .concat(brandName)
          .concat(category)
          .concat(createdAt)
      },

      set(items) {
        Object.assign(this.searchParams, {
          state: [],
          sell_type: [],
          product_name: [],
          brand_name: [],
          vendor_idx: [],
          vendor_name: [],
          legacy_vendor_no: [],
          tagby_product_no: [],
          product_no: [],
          category: [],
          created_at: [],
        })
        items.forEach(item => {
          const [key, value] = item.split(':')
          if (key === '상품상태') {
            const state = this.stateOptions.find(o => o.text === value)
            this.searchParams.state.push(state.value)
          } else if (key === '판매타입') {
            const sellType = this.sellTypeOptions.find(o => o.text === value)
            this.searchParams.sell_type.push(sellType.value)
          } else if (key === '상품명') {
            this.searchParams.product_name.push(value)
          } else if (key === '태그바이품번') {
            this.searchParams.tagby_product_no.push(value)
          } else if (key === '벤더품번') {
            this.searchParams.product_no.push(value)
          } else if (key === '벤더번호') {
            this.searchParams.vendor_idx.push(value)
          } else if (key === '벤더명') {
            this.searchParams.vendor_name.push(value)
          } else if (key === '벤더번호(1.0)') {
            this.searchParams.legacy_vendor_no.push(value)
          } else if (key === '브랜드명') {
            this.searchParams.brand_name.push(value)
          } else if (key === '카테고리') {
            this.searchParams.category.push(value)
          } else if (key === '등록날짜') {
            this.searchParams.created_at.push(value)
          }
        })
      },
    },
  },
  mounted() {
    window.addEventListener('keydown', e => {
      if (e.code === 'Enter' && (e.metaKey || e.ctrlKey)) {
        this.search()
      }
    })
  },
  methods: {
    test() {
      console.log(this.searchParams)
    },
    addTag() {
      const key = this.textCategorySelect?.label
      const value = this.textInput
      if (value != null && value !== '') {
        if (key === '상품명') {
          this.searchParams.product_name.push(value)
        } else if (key === '태그바이품번') {
          this.searchParams.tagby_product_no.push(value)
        } else if (key === '벤더품번') {
          this.searchParams.product_no.push(value)
        } else if (key === '벤더번호') {
          this.searchParams.vendor_idx.push(value)
        } else if (key === '벤더명') {
          this.searchParams.vendor_name.push(value)
        } else if (key === '벤더번호(1.0)') {
          this.searchParams.legacy_vendor_no.push(value)
        } else if (key === '브랜드명') {
          this.searchParams.brand_name.push(value)
        } else if (key === '카테고리') {
          this.searchParams.category.push(value)
        } else if (key === '등록날짜') {
          this.searchParams.created_at.push(value)
        }
      }
      this.textInput = null
    },
    search() {
      this.$emit('search', this.searchParams)
    },
    createProduct() {
      this.$router.push({ name: 'commerce-product-create' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
