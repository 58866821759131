<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <b-tabs>
      <b-tab title="전체">
        <ProductListFilters
          :search-params="searchParams"
          @search="fetchProductList"
        />

        <b-table
          ref="refProductListTable"
          :items="products"
          responsive
          small
          :fields="tableColumns"
          primary-key="idx"
          show-empty
          empty-text="No matching records found"
          :busy="isBusy"
          class="position-relative"
        >
          <template #table-busy>
            <div class="text-center">
              <b-spinner
                variant="primary"
                label="API Loading..."
              />
              <div>
                <strong>Loading...</strong>
              </div>
            </div>
          </template>
          <template #cell(idx)="data">
            <div
              class="detail-link"
              style="width: 40px; font-size:16px;"
              @click="toDetail(data.item)"
            >
              <span>
                {{ data.item.idx }}
              </span>
            </div>
          </template>

          <template #cell(thumbnail_img_url)="data">
            <img
              :src="data.item.thumbnail_img_url"
              width="60"
              height="60"
            >
          </template>

          <template #cell(vendor)="data">
            <div style="width: 100px">
              <span>
                {{ optionalChaining(data.item.vendor).company_name }}
              </span>
            </div>
            <div style="width: 100px">
              <span>(</span>
              <span>
                {{ optionalChaining(data.item.vendor).idx }}
              </span>
              <span
                v-if="optionalChaining(data.item.vendor).legacy_vendor_no"
                style="color: red"
              >
                / {{ optionalChaining(data.item.vendor).legacy_vendor_no }}
              </span>
              <span>)</span>
            </div>
          </template>

          <template #cell(name)="data">
            <div
              class="detail-link"
              @click="toProductDetail(data.item)"
            >
              <div style="width: 200px; font-size:14px;">
                <span>{{ data.item.name }}</span>
                <b-badge
                  v-if="data.item.is_abroad"
                  style="margin: 3px;"
                  variant="success"
                >해외
                </b-badge>
              </div>
              <div
                v-if="data.item.brand_name"
                style="width: 200px; font-size:14px;"
              >
                ({{ data.item.brand_name }})
              </div>
            </div>
          </template>

          <template #cell(product_no)="data">
            <div>
              <div style="width: 200px; font-size:14px;">
                {{ data.item.product_no }}
              </div>
              <div style="width: 200px; font-size:12px;">
                ({{ data.item.tagby_product_no }})
              </div>
            </div>
          </template>

          <template #cell(category)="data">
            <div style="width: 100px">
              {{ resolveCategoryText(data.item.category) }}
            </div>
          </template>

          <template #cell(price)="data">
            <div style="width: 100px">
              {{ (data.item.price).toLocaleString() }}
            </div>
            <div
              v-if="data.item.supply_price"
              style="width: 100px"
            >
              ({{ (data.item.supply_price).toLocaleString() }})
            </div>
          </template>

          <template #cell(sell_type)="data">
            <div style="width: 50px">
              {{ resolveProductSellTypeText(data.item.sell_type) }}
            </div>
          </template>

          <template #cell(created_at)="data">
            <div style="width: 100px">
              {{ data.value }}
            </div>
          </template>

          <template #cell(state)="data">
            <div
              style="width: 100px"
              :style="[data.value === 'CONFIRMING' ? {color: 'yellow'} : '']"
            >
              {{ resolveInvoiceStatusText(data.value) }}
            </div>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
              </span>
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalCount"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-tab>

      <!-- 요청사항 탭 -->
      <b-tab :title="`요청 대기중 ${waitingRequestCount}`">
        <b-table
          small
          :items="requestList"
          responsive
          :fields="tableColumnsForRequest"
          primary-key="idx"
          show-empty
          empty-text="No matching records found"
          :busy="isBusy"
          class="position-relative"
        >
          <template #table-busy>
            <div class="text-center">
              <b-spinner
                variant="primary"
                label="API Loading..."
              />
              <div>
                <strong>Loading...</strong>
              </div>
            </div>
          </template>
          <template #cell(idx)="data">
            <div
              class="detail-link"
              style="width: 40px; font-size:16px;"
              @click="toProductRequest(data.item)"
            >
              <span>
                {{ data.item.idx }}
              </span>
            </div>
          </template>

          <template #cell(thumbnail_img_url)="data">
            <img
              :src="data.item.thumbnail_img_url"
              width="60"
              height="60"
            >
          </template>

          <template #cell(vendor)="data">
            <div style="width: 100px">
              {{ data.item.vendor_idx__company_name }}
            </div>
          </template>

          <template #cell(name)="data">
            <div
              class="detail-link"
              @click="toProductRequest(data.item)"
            >
              <div style="width: 200px; font-size:14px;">
                {{ data.item.name }}
              </div>
              <div
                v-if="data.item.brand_name"
                style="width: 200px; font-size:14px;"
              >
                ({{ data.item.brand_name }})
              </div>
            </div>
          </template>

          <template #cell(product_no)="data">
            <div>
              <div style="width: 200px; font-size:14px;">
                {{ data.item.product_no }}
              </div>
              <div style="width: 200px; font-size:12px;">
                ({{ data.item.tagby_product_no }})
              </div>
            </div>
          </template>

          <template #cell(restocked_at)="data">
            <div style="width: 100px">
              {{ data.value }}
            </div>
          </template>

          <template #cell(sell_type)="data">
            <div style="width: 50px">
              {{ resolveProductSellTypeText(data.item.sell_type) }}
            </div>
          </template>

          <template #cell(reason)="data">
            <div style="width: 100px">
              {{ translateReason(data.item.reason) }}
            </div>
          </template>

          <template #cell(state)="data">
            <div
              style="width: 100px"
              :style="[data.value === 'WAIT' ? {color: 'yellow'} : '']"
            >
              {{ translateRequestState(data.item.state) }}
            </div>
          </template>

          <template #cell(created_at)="data">
            <div style="width: 100px">
              {{ data.value }}
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentRequestPage"
                :total-rows="totalRequestCount"
                :per-page="perPage"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BSpinner,
  BTabs, BTab, BBadge,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import useProductList from './useProductList'
import ProductListFilters from './ProductListFilters.vue'

import invoiceStoreModule from './orderStoreModule'

export default {
  components: {
    ProductListFilters,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BSpinner,
    BTabs,
    BTab,
    BBadge,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
      }
    })

    const productStateList = ref([
      { label: '삭제', value: 'INACTIVE' },
      { label: '승인대기중', value: 'CONFIRMING' },
      { label: '판매중', value: 'ACTIVE' },
      { label: '매진', value: 'SOLDOUT' },
      { label: '숨김상태', value: 'HIDDEN' },
      { label: '작성중', value: 'WRITING' },
      { label: '삭제 요청중', value: 'DELETING' },
    ])

    const {
      isBusy,
      fetchProductList,
      fetchRequestList,
      requestList,
      waitingRequestCount,
      tableColumns,
      tableColumnsForRequest,
      perPage,
      currentPage,
      currentRequestPage,
      totalCount,
      totalRequestCount,
      dataMeta,
      refProductListTable,
      yearFilter,
      monthFilter,
      searchParams,
      products,
      settlementMargin,
      updateProductState,
      resolveInvoiceStatusText,
      resolveProductSellTypeText,
      translateReason,
      translateRequestState,
      resolveCategoryText,
    } = useProductList()

    fetchProductList()
    fetchRequestList()

    const settlementAll = () => {
      settlementMargin({
        year: yearFilter.value,
        month: monthFilter.value,
        mystoreList: [],
      })
    }

    const settlementEach = mystoreUrlPath => {
      settlementMargin({
        year: yearFilter.value,
        month: monthFilter.value,
        mystoreList: [mystoreUrlPath],
      })
    }

    const optionalChaining = obj => (obj == null ? {} : obj)

    const toProductDetail = item => {
      router.push({ name: 'commerce-product-view', params: { idx: item.idx } })
    }

    const toProductRequest = item => {
      router.push({ name: 'commerce-product-request-view', params: { idx: item.idx } })
    }

    return {
      isBusy,
      fetchProductList,
      requestList,
      waitingRequestCount,
      tableColumns,
      tableColumnsForRequest,
      perPage,
      currentPage,
      currentRequestPage,
      totalCount,
      totalRequestCount,
      dataMeta,
      refProductListTable,
      yearFilter,
      monthFilter,
      searchParams,
      products,
      settlementAll,
      settlementEach,
      updateProductState,
      resolveInvoiceStatusText,
      resolveProductSellTypeText,
      translateReason,
      translateRequestState,
      resolveCategoryText,
      productStateList,
      optionalChaining,

      toProductDetail,
      toProductRequest,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.detail-link {
  color:#258BFF;
}

.detail-link:hover {
  transition: 0.2s;
  transform: translateY(-2px);
  cursor: pointer;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.w-200 {
  width: 200px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
